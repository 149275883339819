import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode3.png'

const Episode3 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">these three stooges will be hanging around for a while</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 3: One World Grows Larger</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="One World Grows Larger (asteroid hurtling through space with distant sun in background)" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7764584/height/360/theme/legacy/thumbnail/no/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome again to Planet and Sky, a cosmic love story. If you’ve followed our story from the beginning, you know that it began when two scientists went to investigate a mysterious signal at an inhospitable planet. There, the scientists and their research crew found something unexpected – an ancient story, that of a Planet, and how it had conjoined with an atmosphere. And as the two explorers heard this story, they began, inexorably, to develop feelings for one another, and forget about their mission and the other members of their crew.
            </p>

            <p>
              Now our story continues with a new expedition sent out to locate the missing explorers. Communication between the two scientists and their crew at the base station had been lost. A barrage of information had been received that indicated an intelligent communication emanating from the target location. But the two scientists themselves had gone silent, lost somewhere beneath the frigid surface of the alien planet…
            </p>

            <p>
              A rescue party had been sent in according to established protocol upon the sudden break in the transmission from the exploration team. The three trained specialists entered the cleft into which the scientists had disappeared from a new location some distance away from the original entrance point. The rescue party had the precise coordinates where the man and the woman had disappeared, but were directed to take an alternative route lest the same mysterious fate befall them.
            </p>

            <p>
              The team leader chose a less direct descent that he felt sure would safely intersect the explorers’ path to the mysterious chamber. A risk counselor, followed and monitored the team’s environmental parameters. Lastly, a psychology specialist observed the team’s neural patterns for any anomalies indicative of instability. No sooner had they entered the cavern, however, than their disorientation began.
            </p>

            <p>
              “We seem to be unexpectedly remote from our objective,” said the leader, “but I am confident that we will immediately regain contact with the desired target location.”
            </p>

            <p>
              “That sounds like another way of saying that we’re lost,” noted the risk counselor. “I thought things looked awfully familiar. Are we going in circles?”
            </p>

            <p>
              “I don’t see any reason for immediate concern,” reported the psychologist. “Our neural parameters are normal and one way is as good as another.”
            </p>

            <p>
              The others thought that his comment was strange, and at that moment the strangeness increased. The cavern walls slowly became translucent. The murky bedrock grew gradually darker and small, bright pinpricks of light appeared. All three crew members started feeling extremely disoriented.
            </p>

            <p>
              “We’re on track… I’m sure… to somewhere… I rather think… it is somewhat.. OK,” mumbled the team leader as he stumbled slightly and then slowly sank to his knees.
            </p>

            <p>
              “Oh no… not good… not good at all…” said the counselor with a rising note of panic. “this is not going to end well I fear… bad things… very bad…” She also fell to her knees as the walls became darker and the filaments of light brighter. One light shone brighter than the others
            </p>

            <p>
              The third member of the crew, responsible for monitoring the mental states of himself and his companions, turned slowly in circles with a dazed and silly grin as he settled contentedly to the floor - which was also dissolving rapidly into a night sky.
            </p>

            <p>
              The members of the rescue party were losing any sense of the consciousness they had previously possessed. In its place was a dim awareness that they were isolated in a very dark region with bright pinpoints scattered in all directions. What had been the walls of the cavern now appeared to be translucent ice and they appeared to be embedded within it, floating in the vastness of space. They still had vague thoughts, and memories of their former specialties.
            </p>

            <p>
              The first crewman tried to project his assurance that all would be well, though they were far from their ancient world and were awaiting a new place to call home. His message came through in song,
            </p>

            <p>
              “Far from the star of our home world we rest in this ice world we wait for new land.”
            </p>

            <p>
              The risk specialist still felt highly attuned to a dangerous situation. She recalled a powerful explosion of some kind in her earliest memory, as the ground they’d been inhabiting was lifted into the dark emptiness of deep space. She sang in response,
            </p>

            <p>
              “Blown into space by a terrible blast. A horrible fate is awaiting us here in the void”.
            </p>

            <p>
              The psychologist felt only a blissful quiet and peace. In fact, to the others he appeared to have lost consciousness entirely. On his face - or what remained of what had once been a face - was a blissful half smile. His thoughts emanated tunefully to the others,
            </p>

            <p>
              “Deep space is quiet and restful and cold... hardened ’gainst radiation we sleep.”
            </p>

            <p>
              The rescue team was dissolving now. They held some awareness, but like the astrobiologist and her companion the planetologist before them, their perspective was shifting dramatically. Each of the individuals began to feel a loss of solidity. Their bodies became dispersed and granular. They were transforming into an aggregate of unicellular beings. They had become microscopic travelers embedded in ice, floating in the starry night.
            </p>

            <p>
              Now a new point of light grew steadily brighter. The first traveler’s words were somehow conveyed to its companions without being sung aloud,
            </p>

            <p>
              Something comes slowly and makes itself known… now we're on track to a new star.”
            </p>

            <p>
              The second traveler, escalated quickly into a state on the verge of terror. It also experienced movement, toward this new point of light and away from the familiar one. This concerned it greatly, and it let its feeling be known,
            </p>

            <p>
              “This monster is pulling us faster we move, away from our neighbors far far away.”
            </p>

            <p>
              The third traveler remained unconcerned in any way. It was comfortable and unperturbed. It sensed a growing speed but found the acceleration pleasant and enjoyable. It also became aware of a growing warmth, though not enough to melt the ice that surrounded them. It sang contentedly,
            </p>

            <p>
              “A new journey finds us speed fast on our way, the light getting brighter, the cold fades.”
            </p>

            <p>
              The three travelers were embedded in a tiny astronomical object floating far out in space. It had once been a part of a larger world, rich with life. They were representatives of this life in microbial form and encased in an icy rock that had been shot off into the outer regions of its original star system. It had drifted further and further away from its native sun until it was barely held by a tenuous thread of gravitation force. Another star had intersected the vicinity of the distant sun in its travels through the depths of space. As it grew closer its own gravitational well overcame the weak force of the original one. The planetoid was gradually pulled more strongly by the new star and began its long fall toward its new master.
            </p>

            <p>
              The colony of microbes corresponding to the first traveler was elated. It felt certain that the change in fortune was a positive development, and gleefully sang,“Here comes a source of new light and heat - a golden beacon - our deliverance!
            </p>

            <p>
              The second traveler felt that there could only be one result of the calamitous new direction being taken by their strange vessel. It could feel the growing heat and light and could sense nothing short of imminent catastrophe. It felt sure that their journey would end in disaster. In dreadful verse it exclaimed,
            </p>

            <p>
              “We rush to this nightmare, our doom to be scorched, blackened, cold…  and dead!”
            </p>

            <p>
              The third traveler remained as always, placid and calm. It saw that all possibilities were conceivable, but chose to consider the benefits of a kindly resting place, wherever and whatever it might be. It consolingly intoned,
            </p>

            <p>
              “Maybe a new place to live, eat, and sleep… on a nurturing world?”
            </p>

            <p>
              The tiny travelers pondered separately as their chunk of ice increased speed and began disintegrating as it descended, growing a bright dusty tail as it sped on its way into the interior realms of this new star system. At the same time, a much larger object traveled on an intercepting course around the same star; a world upon which a new connection had just been established between a dense body and an airy spirit.
            </p>

            <p>
              Planet and Sky were lost in the joy of their ethereal romance and had no awareness of the growing dot in space far above the distant reaches of atmosphere. Within the icy comet, the three entities which had once been members of a rescue team became aware of an object of growing significance in their path, unaware that it was the original objective of their mission. Tiny and helpless to do otherwise, the travelers felt themselves catapulting downward into the new atmosphere of the unfamiliar world.
            </p>

            <p>
              In unison they shared this new awareness and sang,
            </p>

            <p>
              “One world… grows larger…”
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode3
